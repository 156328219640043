import { HttpErrorResponse } from '@angular/common/http';
import { Component, computed, HostListener, OnDestroy, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PathIds } from '@core/constants/url-constants';
import { Dictionary } from '@core/models/dictionary';
import { AlertService } from '@core/services/alert.service';
import { RightSidenavigationService } from '@core/services/right-sidenavigation.service';
import { WahlDetailsuche } from '@shared/constants/shared-constants';
import { AdressenClient } from '@shared/models/adressenClient';
import {
    Datenabgleich,
    IdentitaetsnachweisTyp,
    NullableBoolean,
    OffeneBearbeitungen,
    PersonenTyp,
    SucheWahlkartenStatusEnum,
    WaehlerStatus,
    WahlSuchTyp,
    Wahltyp,
    ZustelladresseVorhanden
} from '@shared/models/enums';
import { SearchCriteria } from '@shared/models/searchCriteria';
import { SprengelInformation } from '@shared/models/sprengel-information';
import { WahlbestandSuchkriterien } from '@shared/models/wahlbestandSuchkriterien';
import { WahlbezugsadresseDaten } from '@shared/models/wahlbezugsadresseDaten';
import { WahlbezugsAdresseResponse } from '@shared/models/wahlbezugsAdresseResponse';
import { Wahldaten } from '@shared/models/wahldaten';
import { AntragsartEnum, ZustellartEnum } from '@shared/models/wahlkarten-enums';
import { AdressenService } from '@shared/services/adressen.service';
import { DetailsucheService } from '@shared/services/detailsuche.service';
import { FormErrorMessageService } from '@shared/services/form-error-message.service';
import { SchnellsucheService } from '@shared/services/schnellsuche.service';
import { combineLatest, EMPTY, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, map, share, startWith, switchMap } from 'rxjs/operators';
import { WahlUiConstants } from 'src/app/buergerservice/wahl/models/wahlUiConstants';

@Component({
    selector: 'k5-detail-search-wahl',
    templateUrl: './detail-search-wahl.component.html',
    styleUrls: ['./detail-search-wahl.component.scss']
})
export class DetailSearchWahlComponent implements OnInit, OnDestroy {
    title = 'Wahlberechtigte suchen';

    WAHLDETAILSUCHE = WahlDetailsuche;

    searchCriteriaForm = this.formBuilder.group({
        // Wähler
        sprengelNummer: new FormControl<number[] | null>(null),
        geburtsdatum: '',
        name: new FormControl<string>('', [Validators.minLength(2)]),
        wahlbezugsadresse: '',
        wahlkartenabo: new FormControl<NullableBoolean | null>(null),
        zustellAdresse: new FormControl<ZustelladresseVorhanden | null>(null),
        personenTyp: '',
        status: new FormControl<number[] | null>(null),
        datenabgleich: new FormControl<number[] | null>(null),
        abgleichdatum: '',
        vorgezogeneStimmabgabe: new FormControl<NullableBoolean | null>(null),
        sonderwahlbehoerde: new FormControl<NullableBoolean | null>(null),
        // Wahlkarte
        offeneBearbeitungen: '',
        wkStatus: '',
        wkStatusZeitraum: '',
        antragsArt: new FormControl<number | null>(null),
        authentifizierungsArt: new FormControl<number[] | null>(null),
        zustellArt: new FormControl<number | null>(null),
        besondereWahlbehoerde: new FormControl<NullableBoolean | null>(null),
        einschreibnummer: '',
        wkNotiz: '',
        wkSchablone: new FormControl<NullableBoolean | null>(null)
    });

    selectedWahlbezugsadresse: WahlbezugsadresseDaten | null = null;

    // Observable der Sprengelnummern für den Wahlbestand aus dem Backend
    sprengelInformation$: Observable<SprengelInformation[]> = EMPTY;
    // Wahlbezugsadressen aus dem Backend
    wahlbezugsadressen: WahlbezugsadresseDaten[] = [];
    // Suchstring für die Suche nach einer Wahlbezugsadresse
    adresseQueryString: string = '';
    // Observable auf die gefilterten Wahlbezugsadressen
    filteredWahlbezugsadresse$: Observable<WahlbezugsadresseDaten[]> = EMPTY;
    // String für das highlighten des Suchstrings in den gefilterten Ergebnissen
    toHighlight: string = '';
    // Sollen die Hausnummer oder Postleitzahl im Vorschlagfeld mitangezeigt werden
    showHausnummer: boolean = false;
    showPlz: boolean = false;
    // Soll bei der Suchoption Status Wahlkarte der Eintrag Rückerfassung angezeigt werden
    showWkStatusRueckerfassung: boolean = false;

    subscription = new Subscription();

    /**
     * Definiert alle optionalen Suchkriterien der Detailsuche
     * @remarks Signals triggern nur bei Änderung der Referenz, darum bei hinzufügen optionaler Suchkriterien neue Instanz zuweisen
     * @link https://angular.dev/guide/signals#writable-signals
     */
    optionalSearchCriteria = signal<SearchCriteria[]>([
        { key: 'name', name: 'Name', selected: false, kind: WahlSuchTyp.Waehler },
        { key: 'einschreibnummer', name: 'Einschreibnummer', selected: false, kind: WahlSuchTyp.Waehler },
        { key: 'wkNotiz', name: 'Notiz zur Wahlkarte', selected: false, kind: WahlSuchTyp.Waehler },
        { key: 'authentifizierungsArt', name: 'Identitätsnachweis', selected: false, kind: WahlSuchTyp.Waehler },
        { key: 'wkSchablone', name: 'Schablone Wahlkarte', selected: false, kind: WahlSuchTyp.Waehler }
    ]);

    /**
     * Beinhaltet die Keys der optionalen Suchkriterien, welche ausgewählt sind
     * @remarks wird nur bei Änderung an optionalSearchCriteria aktualisiert
     */
    visibleSearchCriteriaComputed = computed<string[]>(() =>
        this.optionalSearchCriteria()
            .filter((searchCriteria: SearchCriteria) => searchCriteria.selected)
            .map((searchCriteria: SearchCriteria) => searchCriteria.key)
    );

    PERSONEN_TYP = PersonenTyp;

    WAEHLER_STATUS = WaehlerStatus;

    DATENABGLEICH = Datenabgleich;

    NULLABLE_BOOLEAN = NullableBoolean;

    ZUSTELLADRESSE_VORHANDEN = ZustelladresseVorhanden;

    OFFENE_BEARBEITUNGEN = OffeneBearbeitungen;

    WAHLKARTEN_STATUS = SucheWahlkartenStatusEnum;
    ANTRAGS_ART = AntragsartEnum;

    // Identitaetsnachweise werden über das Backend eingeschränkt, daher kein Enum
    IDENTITAETSNACHWEIS: { value: IdentitaetsnachweisTyp }[] = [
        { value: IdentitaetsnachweisTyp.IdAustria },
        { value: IdentitaetsnachweisTyp.Antragscode },
        { value: IdentitaetsnachweisTyp.Reisepass },
        { value: IdentitaetsnachweisTyp.AndereUrkunde }
    ];

    ZUSTELLARTEN: { value: ZustellartEnum }[] = [
        { value: ZustellartEnum.Persoenlich },
        { value: ZustellartEnum.Standardpost },
        { value: ZustellartEnum.AnderePerson },
        { value: ZustellartEnum.DurchBoten },
        { value: ZustellartEnum.Einschreiben }
    ];

    // Erweiterte Suchkriterien anzeigen
    extended = false;

    // Id der Wahl für die Detailsuche z.B.: Suche nach Wahlbezugsadressen im Wahlbestand
    wahlId: string = WahlUiConstants.GUID_EMPTY;

    // KeyValuePair-Objekt für vergleich auf Veränderung
    oldKeyValuePairs: Dictionary<string> = {};

    // Flag ob die Wahl einen vorgezogenen Wahltag hat.
    vorgezogenerWahltag: boolean = false;

    // Flag ob bei der Wahl die Sonderwahlbehoerde aktiv ist.
    isSonderwahlbehoerdeAktiv: boolean = false;

    constructor(
        public formErrorService: FormErrorMessageService,
        private readonly rightSidenavigationService: RightSidenavigationService,
        private readonly formBuilder: FormBuilder,
        private readonly schnellsucheService: SchnellsucheService,
        private readonly detailsucheService: DetailsucheService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly alertService: AlertService,
        private readonly adressenService: AdressenService
    ) {}

    ngOnInit(): void {
        // Unterste aktive Route holen, da die Detailsuche in der App-Komponente eingegliedert ist und dort die URL-Parameter fehlen
        let leafRoute: ActivatedRoute = this.route;
        while (leafRoute?.firstChild) {
            leafRoute = leafRoute.firstChild;
        }

        this.sprengelInformation$ = leafRoute.params.pipe(
            switchMap((params: Params) => {
                if (Object.hasOwn(params, PathIds.WAHL_ID)) {
                    this.wahlId = params[PathIds.WAHL_ID];

                    return this.detailsucheService.loadWahldetails(this.wahlId);
                }
                return of(null);
            }),
            switchMap((data: Wahldaten | null): Observable<SprengelInformation[]> => {
                if (data?.bundesland === WahlUiConstants.BUNDESLAND_NOE) {
                    if (data?.wahltyp === Wahltyp.Land) {
                        this.ZUSTELLARTEN = [
                            { value: ZustellartEnum.Persoenlich },
                            { value: ZustellartEnum.AnderePerson },
                            { value: ZustellartEnum.DurchBoten },
                            { value: ZustellartEnum.EinschreibenRSb }
                        ];
                    }

                    // PBI #18166: Erweiterung um Zustellart PerRSbRSa für Gemeindewahlen in NOE
                    if (data?.wahltyp === Wahltyp.Gemeinde) {
                        this.ZUSTELLARTEN = [
                            { value: ZustellartEnum.Persoenlich },
                            { value: ZustellartEnum.AnderePerson },
                            { value: ZustellartEnum.DurchBoten },
                            { value: ZustellartEnum.Einschreiben },
                            { value: ZustellartEnum.PerRSbRSa }
                        ];
                    }
                }

                // PBI #17427: Zustellart PerRSbRSa für BGLD
                if (data?.bundesland === WahlUiConstants.BUNDESLAND_BGLD) {
                    this.ZUSTELLARTEN = [
                        { value: ZustellartEnum.Persoenlich },
                        { value: ZustellartEnum.Standardpost },
                        { value: ZustellartEnum.AnderePerson },
                        { value: ZustellartEnum.DurchBoten },
                        { value: ZustellartEnum.Einschreiben },
                        { value: ZustellartEnum.PerRSbRSa }
                    ];
                }

                // Die Suchoption nach Rückerfassung im Suchfeld Wahlkarte Status soll nur für Wahlen in Tirol aktiv sein
                this.showWkStatusRueckerfassung = data?.bundesland === WahlUiConstants.BUNDESLAND_TIR;

                // Die optionale Suchoption nach vorgezogener Stimmabgabe soll nur sichtbar sein,
                // wenn die wahl einen vorgezogenen Wahltag hat.
                this.vorgezogenerWahltag = !!data?.vorgezogenerWahltag;
                if (
                    this.vorgezogenerWahltag &&
                    !this.optionalSearchCriteria().find(
                        (searchCriteria: SearchCriteria) =>
                            searchCriteria.key === WahlDetailsuche.VORGEZOGENE_STIMMABGABE
                    )
                ) {
                    this.optionalSearchCriteria.update((searchCriteriaList: SearchCriteria[]) => [
                        ...searchCriteriaList,
                        {
                            key: WahlDetailsuche.VORGEZOGENE_STIMMABGABE,
                            name: 'Vorgezogene Stimmabgabe',
                            selected: false,
                            kind: WahlSuchTyp.Waehler
                        }
                    ]);
                }

                // Die optionale Suchoption nach Sonderwahlbehörde soll nur für Wahlen in Tirol oder Kärnten aktiv sein
                this.isSonderwahlbehoerdeAktiv =
                    (data?.bundesland === WahlUiConstants.BUNDESLAND_TIR ||
                        data?.bundesland === WahlUiConstants.BUNDESLAND_KTN) &&
                    (data?.wahltyp === Wahltyp.Land || data?.wahltyp === Wahltyp.Gemeinde);

                if (
                    this.isSonderwahlbehoerdeAktiv &&
                    !this.optionalSearchCriteria().find(
                        (searchCriteria: SearchCriteria) => searchCriteria.key === WahlDetailsuche.SONDERWAHLBEHOERDE
                    )
                ) {
                    this.optionalSearchCriteria.update((searchCriteriaList: SearchCriteria[]) => [
                        ...searchCriteriaList,
                        {
                            key: WahlDetailsuche.SONDERWAHLBEHOERDE,
                            name: 'Sonderwahlbehörde',
                            selected: false,
                            kind: WahlSuchTyp.Waehler
                        }
                    ]);
                }

                // Suche nach Wahlbezugsadressen aus dem Wahlbestand im Eingabefeld der Detailsuche für die Wahl
                this.activateWahlbezugsadresseSuche();

                // Sprengel der Wahl werden vom Adressenservice abgefragt
                const sprengelListeObservable: Observable<AdressenClient.Sprengel[] | null> = data?.sprengelgruppeId
                    ? this.adressenService
                          .getSprengelByGruppeId(data.sprengelgruppeId)
                          .pipe(
                              map(
                                  (
                                      sprengelListeResponse: AdressenClient.SprengelListeResponse | null | undefined
                                  ): AdressenClient.Sprengel[] | null => sprengelListeResponse?.sprengel ?? null
                              )
                          )
                    : EMPTY.pipe(startWith(null)); // Emittiert null vor complete um combineLatest auszuführen

                return combineLatest(
                    [
                        this.detailsucheService.loadSprengel(this.wahlId).pipe(
                            catchError(() => {
                                return [];
                            })
                        ),
                        sprengelListeObservable.pipe(
                            catchError(() => {
                                return of(null);
                            })
                        )
                    ],
                    (numbers: number[], sprengelListe: AdressenClient.Sprengel[] | null): SprengelInformation[] =>
                        sprengelListe && sprengelListe.length > 0
                            ? sprengelListe
                                  .filter(
                                      (
                                          sprengel: AdressenClient.Sprengel
                                          // Definiert einen Typ analog dem Typ AdressenClient.Sprengel dessen property 'nummer' required ist
                                          // - Omit gibt einen neuen Typen zurück, der alle properties eines Typen (AdressenClient.Sprengel)
                                          // bis auf den spezifizierten Key ('nummer') enthält
                                          // - Required setzt alle properties des angegebenen Typen als required
                                          // - Pick gibt einen neuen Typen zurück, der nur die spezifizierten Properties (nummer) eines Typen (AdressenClient.Sprengel) enthält
                                          // Beispiele siehe https://www.typescriptlang.org/docs/handbook/utility-types.html
                                      ): sprengel is Omit<AdressenClient.Sprengel, 'nummer'> &
                                          Required<Pick<AdressenClient.Sprengel, 'nummer'>> =>
                                          sprengel.nummer !== undefined &&
                                          sprengel.nummer !== null &&
                                          numbers.includes(sprengel.nummer)
                                  )
                                  .map<SprengelInformation>(
                                      (
                                          sprengel: Omit<AdressenClient.Sprengel, 'nummer'> &
                                              Required<Pick<AdressenClient.Sprengel, 'nummer'>>
                                      ) => ({
                                          sprengelNummer: sprengel.nummer,
                                          sprengelBezeichnung: sprengel.bezeichnung
                                      })
                                  )
                            : numbers.map<SprengelInformation>((nummer: number) => ({
                                  sprengelNummer: nummer,
                                  sprengelBezeichnung: ''
                              }))
                );
            }),
            // Ergebnis wird geshared, damit bei meherern subscriptions das Observable nur einmal ausgeführt wird.
            share()
        );

        // Subscription für die Aktualisierung der Eingefelder in der Detailsuche
        this.subscription.add(
            // Es wird auf ein Ergebnis des sprengelInformation$ Observables gewartet, damit sichergestellt ist,
            // dass das optionale Suchkriterium vorgezogene Stimmabgabe vorhanden ist.
            // Erst dann werden die Detailsucheparameter angenommen.
            this.sprengelInformation$.pipe(switchMap(() => this.detailsucheService.detailsucheParameter$)).subscribe({
                next: (detailsucheParameter: Dictionary<string>) => {
                    if (detailsucheParameter && Object.keys(detailsucheParameter).length > 0) {
                        this.mapKeyValuePairsToFormFields(detailsucheParameter);
                    } else {
                        this.resetDetailSearch();
                    }
                },
                error: () => console.error('Fehler beim Aktualisieren der Detailsuche')
            })
        );
    }

    /**
     * Freigabe der Subscriptions
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Setzt die aktuell ausgewählte Wahlbezugsdresse
     * @param adresse Wahlbezugsadresse
     */
    selectWahlbezugsadresse(adresse: WahlbezugsadresseDaten): void {
        this.selectedWahlbezugsadresse = adresse;
    }

    /**
     * Ermöglicht die Suche nach einer Wahlbezugsadresse aus dem Wahlbestand im Einabefelde der Detailsuche für die Wahl
     */
    activateWahlbezugsadresseSuche(): void {
        this.subscription.add(
            this.searchCriteriaForm.controls.wahlbezugsadresse.valueChanges
                .pipe(
                    distinctUntilChanged(),
                    switchMap((query: string | null): Observable<WahlbezugsAdresseResponse | null> => {
                        // Zurücksetzen der selektierten Wahlbezugsadresse
                        if (this.selectedWahlbezugsadresse?.adresse !== query) {
                            this.selectedWahlbezugsadresse = null;
                        }
                        if (!query || query.length < 1) {
                            return of(null);
                        }
                        this.adresseQueryString = query;

                        return this.detailsucheService.queryWahlbezugsAdressen(this.wahlId, query).pipe(
                            catchError((error: HttpErrorResponse) => {
                                this.alertService.errorResponse(error, 'Fehler bei der Abfrage der Wahlbezugsadresse.');
                                return of(null);
                            })
                        );
                    })
                )
                .subscribe({
                    next: (data: WahlbezugsAdresseResponse | null) => {
                        if (data) {
                            this.wahlbezugsadressen = data.adressdaten;
                            this.showHausnummer =
                                this.wahlbezugsadressen?.length > 0 && this.wahlbezugsadressen[0].hausnummer !== null;
                            this.showPlz =
                                this.wahlbezugsadressen?.length > 0 && this.wahlbezugsadressen[0].plz !== null;
                            this.filteredWahlbezugsadresse$ = of(data.adressdaten);
                            this.toHighlight = this.adresseQueryString;
                        }
                    }
                })
        );
    }

    /**
     * Liefert true zurück, wenn im Feld WahlkartenStatus der Wert auf einen gültigen Wert gesetzt wird.
     * Andernfalls false.
     * Mit diesen Werten soll das Eingabefeld für den WahlkartenStatus-Zeitraum sichtbar gemacht werden.
     * @returns boolean true oder false
     */
    wkStatusSelected(): boolean {
        let result = true;

        // Auswahl des Wahlkartenstatus 'Keine Wahlkarte' oder 'leer'
        if (!this.searchCriteriaForm.value.wkStatus) {
            this.searchCriteriaForm.controls.wkStatusZeitraum.setValue(null);
            result = false;
        }

        return result;
    }

    /**
     * Liefert für das Eingabefeld der Wahlbezugsadresse in der Detailsuche die,
     * aus der ausgewählten Adresse des Autocomplete Feldes,
     * die Adresse als String des WahlbezugsAdresseResponse-Objekts zurück.
     * @param wahlbezugsadresse Adresse
     * @returns Adresse als String oder null, auch bei "" wird null zurückgegeben
     */
    displayFn(wahlbezugsadresse: string | null): string | null {
        return wahlbezugsadresse || null;
    }

    /**
     * Umschalten der Sichtbarkeit der Suchkriterienauswahl
     */
    toggleSearchCriteriaSelection(): void {
        this.extended = !this.extended;
    }

    /**
     * Behandelt die Änderung der Suchkriterienselektion und
     * aktualisiert die Auswahl der Suchkriterien
     * @param selectionListChange selection change event
     */
    searchCriteriaSelectionChange(selectionListChange: MatSelectionListChange): void {
        if (selectionListChange.options.length > 0) {
            const criteriaKey: string = selectionListChange.options[0].value;
            const selected: boolean = selectionListChange.options[0].selected;
            if (criteriaKey) {
                this.updateOptionalSearchCriteriaSelection(criteriaKey, selected);
            }
        }
    }

    /**
     * Setzt die Sichtbarkeit und damit die Eingabemöglichkeit eines optionalen
     * Eingabefelds in der Detailsuche.
     * @param criteriaKey string Name des Eingabefelds
     * @param selected boolean flag, ob das Eingabefeld ausgewählt ist
     */
    updateOptionalSearchCriteriaSelection(criteriaKey: string, selected: boolean): void {
        const searchCriteria: SearchCriteria | undefined = this.optionalSearchCriteria().find(
            (criteria: SearchCriteria) => criteria.key === criteriaKey
        );
        if (searchCriteria && searchCriteria.selected !== selected) {
            searchCriteria.selected = selected;
            this.optionalSearchCriteria.update((optionalSearchCriteria: SearchCriteria[]) => [
                ...optionalSearchCriteria
            ]);
        }
    }

    /**
     * Beim drücken der Enter-Taste wird das Formular ausgelesen und die Detailsuche
     * auf dem Wahlbestand ausgeführt.
     * Die Daten aus dem Schnittstellenaufruf werden in das DetailsucheService zurückgeschrieben, da dieses die Datenhoheit
     * über die Daten besitzt und andere Komponenten die akutalisierten Daten auslesen können.
     */
    submitOnEnter(): void {
        this.schnellsucheService.emitClearSearchInput();
        if (this.searchCriteriaForm.touched && this.searchCriteriaForm.valid) {
            const guid = this.detailsucheService.generateSessionStorageKey();
            const keyValuePairs: Dictionary<string> = this.mapFormfieldsToKeyValuePairs(this.searchCriteriaForm);

            // Als Absicherung, wenn die Wahl keinen vorgezogenen Wahltag hat aber nach vorgezogener Stimmabgabe gesucht wird, wird der Eintrag entfernt.
            if (!this.vorgezogenerWahltag && Object.keys(keyValuePairs).includes('vorgezogeneStimmabgabe')) {
                delete keyValuePairs['vorgezogeneStimmabgabe'];
            }

            // redirect auf die Wahlbestandsliste um die Suche zu triggern
            // siehe detail-search-component.ts
            if (Object.keys(keyValuePairs).length > 0) {
                // Durchführen der Suche ist Aufgabe der Komponente für die Anzeige des Wahlbestands
                // Für die Suche werden die Parameter an die URL angefügt
                const wahlbestandSuchkriterien: WahlbestandSuchkriterien = {
                    suchkriterien: keyValuePairs,
                    schnellsucheQuery: null,
                    sort: null
                };
                this.detailsucheService.saveSearchInStorage(wahlbestandSuchkriterien, guid);
                this.router.navigate([], { relativeTo: this.route, queryParams: { searchKey: guid } });
            } else {
                this.router.navigate([], { relativeTo: this.route, queryParams: {} });
            }
        }
    }

    /**
     * Iteriert über alle Eingabefelder der Detailsuche und wandelt die befüllten Eingabefelder
     * in ein KeyValuePair<string, string>-Objekt um.
     * Dabei entsprechen
     *     die Keys dem FromControlNamen des Eingabefelds, und
     *     die Values dem Wert im Eingabefelds.
     * @param form FormGroup der Detailsuche
     * @returns KeyValuePair
     */
    mapFormfieldsToKeyValuePairs(form: FormGroup): Dictionary<string> {
        const keyValuePairs: Dictionary<string> = {};

        Object.entries(form.controls).forEach(([key, control]) => {
            // Eingabfelder nur auslesen wenn befüllt
            if (control.value != undefined) {
                const kvPairs = this.returnFormControlAsKeyValuePair(key, control.value);
                kvPairs.forEach((kvPair: Dictionary<string>) => {
                    Object.assign(keyValuePairs, kvPair);
                });
            }
        });

        return keyValuePairs;
    }

    /**
     * Iteriert über alle Key-Value-Pairs des Parameters und befüllt über die Keys
     * die Eingabefelder in der Detailsuche für die Wahl.
     * Die Werte sind als Strings vorhanden und müssen für die Eingabefelder entsprechend geparsed werden.
     * @param detailsucheParameter KeyValuePair
     */
    mapKeyValuePairsToFormFields(detailsucheParameter: Dictionary<string>): void {
        let wahlbezugsadresse: WahlbezugsadresseDaten = {
            strasse: '',
            hausnummer: '',
            plz: '',
            ort: '',
            adresse: '',
            skz: null,
            anzahlWaehler: null
        };

        // Entfernt Menüeinträge für Wahlkarten-, Etiketten- und Postaufgabelistendruck
        this.resetOffeneBearbeitungenObservables();

        Object.entries(detailsucheParameter).forEach(([key, value]) => {
            switch (key) {
                case WahlDetailsuche.SONDERWAHLBEHOERDE:
                case WahlDetailsuche.VORGEZOGENE_STIMMABGABE:
                case WahlDetailsuche.WAHLKARTENABO:
                case WahlDetailsuche.BES_WAHLBEHOERDE:
                case WahlDetailsuche.WK_SCHABLONE: {
                    this.updateOptionalSearchCriteriaSelection(key, true);
                    const nullableBoolean = value === 'true' ? NullableBoolean.Ja : NullableBoolean.Nein;
                    this.searchCriteriaForm.get(key)?.setValue(nullableBoolean);
                    break;
                }
                // Wahlbezugsadresse
                case WahlDetailsuche.STRASSE:
                case WahlDetailsuche.HAUSNUMMER:
                case WahlDetailsuche.PLZ:
                case WahlDetailsuche.ORT:
                    wahlbezugsadresse[key] = value;
                    break;
                // Sprengelnummer, Personentyp, Wählerstatus Authentifizierungsart und Datenablgeich
                // werden gleich behandelt
                case WahlDetailsuche.SPRENGEL_NUMMER:
                case WahlDetailsuche.PERSONENTYP:
                case WahlDetailsuche.STATUS:
                case WahlDetailsuche.AUTHENTIFIZIERUNGSART:
                case WahlDetailsuche.DATENABGLEICH:
                    // Zahlen sind als ein String, mit ',' getrennt, zusammengefügt
                    // Aufteilen und zu Zahlen konvertieren
                    if (value !== '') {
                        this.updateOptionalSearchCriteriaSelection(key, true);
                        const numbers = value.split(',').map(Number);
                        this.searchCriteriaForm.get(key)?.setValue(numbers);
                    }
                    break;
                case WahlDetailsuche.ZUSTELLADRESSE_VORHANDEN:
                case WahlDetailsuche.ANTRAGSART:
                case WahlDetailsuche.ZUSTELLART:
                    // Umwandlung auf number mit unary operator '+'
                    this.searchCriteriaForm.get(key)?.setValue(+value);
                    break;
                // Im Falle des Wahlkartenstatus und der offenen Bearbeitung wird auch ein Observable im
                // DetailsucheService aktualisiert um Menüeinträge in der Wahlbestandsliste zu triggern
                case WahlDetailsuche.OFFENE_BEARBEITUNGEN:
                    this.setFormValueOffenBerbeitung(value, key);
                    break;
                // Im Falle des Wahlkartenstatus wird auch ein Observable im DetailsucheService aktualisiert
                // um Menüeinträge in der Wahlbestandsliste zu triggern
                case WahlDetailsuche.WK_STATUS:
                    this.setFormValueWahlkarteStatus(value, key);
                    break;
                case WahlDetailsuche.GEBURTSDATUM:
                case WahlDetailsuche.WK_STATUS_ZEITRAUM:
                case WahlDetailsuche.ABGLEICHDATUM:
                    this.searchCriteriaForm.get(key)?.setValue(value);
                    break;
                // Erweiterte Suchkriterien
                case WahlDetailsuche.WK_NOTIZ:
                case WahlDetailsuche.EINSCHREIBNUMMER:
                case WahlDetailsuche.NAME:
                    // Suchkriterium mit Wert soll auch angezeigt werden
                    this.updateOptionalSearchCriteriaSelection(key, true);
                    this.searchCriteriaForm.get(key)?.setValue(value);
                    break;
                default:
                    this.alertService.info(`Suchparameter ${key} unbekannt.`);
            }
        });

        // Wahlbezugsadresse benötigt extra Schritt
        wahlbezugsadresse = this.completeWahlbezugsadresseDatenObject(wahlbezugsadresse);
        // Nur einfügen wenn Werte vorhanden sind
        if (wahlbezugsadresse.adresse !== '') {
            this.selectedWahlbezugsadresse = wahlbezugsadresse;
            this.searchCriteriaForm.controls.wahlbezugsadresse.setValue(wahlbezugsadresse.adresse);
        }
    }

    /**
     * Liefert ein passendes Array von Key-Value-Paaren für das jeweilige
     * Eingabefeld und dessen enthaltenem Wert zurück.
     * Alle Werte in den Eingabefeldern werden zu einem String umgewandelt.
     * @param key Name des Eingabefeldes
     * @param value Wert im Eingabefeld
     * @returns Array<KeyValuePair>
     */
    returnFormControlAsKeyValuePair(key: string, value: unknown): Dictionary<string>[] {
        switch (key) {
            // Nullable Values: JA, NEIN
            case WahlDetailsuche.SONDERWAHLBEHOERDE:
            case WahlDetailsuche.VORGEZOGENE_STIMMABGABE:
            case WahlDetailsuche.WAHLKARTENABO:
            case WahlDetailsuche.BES_WAHLBEHOERDE:
            case WahlDetailsuche.WK_SCHABLONE:
                if (value === NullableBoolean.Ja) {
                    return [{ [key]: 'true' }];
                }
                if (value === NullableBoolean.Nein) {
                    return [{ [key]: 'false' }];
                }
                return [];
            // Wahlbezugsadresse
            case WahlDetailsuche.WAHLBEZUGSADRESSE:
                // Im Eingabefeld der Wahlbezugsadresse ist als Value ein string
                // Das richtige Objekt ist separat abgelegt.
                if (value) {
                    let adresse: WahlbezugsadresseDaten = {
                        adresse: value as string,
                        hausnummer: '',
                        ort: '',
                        plz: '',
                        strasse: '',
                        anzahlWaehler: null,
                        skz: null
                    };
                    if (this.selectedWahlbezugsadresse) {
                        adresse = this.selectedWahlbezugsadresse;
                    }

                    return [
                        { [WahlDetailsuche.STRASSE]: adresse.strasse },
                        { [WahlDetailsuche.HAUSNUMMER]: adresse.hausnummer },
                        { [WahlDetailsuche.PLZ]: adresse.plz },
                        { [WahlDetailsuche.ORT]: adresse.ort }
                    ];
                }
                return [];
            // Sprengelnummer
            // Personentyp
            // Wählerstatus
            case WahlDetailsuche.SPRENGEL_NUMMER:
            case WahlDetailsuche.PERSONENTYP:
            case WahlDetailsuche.STATUS: {
                // Die selektierten Werte des Wählerstatus werden als Array von Zahlen übergeben
                // und müssen daher mit 'join' zu einem Kommaseparierten String gewandelt werden
                let values: number[] = [];
                if (value !== undefined && value !== null && (value as number[]).length > 0) {
                    values = value as number[];
                    return [{ [key]: values.join(',') }];
                }
                return [];
            }
            case WahlDetailsuche.OFFENE_BEARBEITUNGEN:
                // Im Falle der offenen Bearbeitung wird auch ein Observable im DetailsucheService aktualisiert
                // um Menüeinträge in der Wahlbestandsliste zu triggern
                return this.mapOffeneBearbeitungenToKeyValuePair(value, key);
            default:
                // Alle anderen Werte, können falls vorhanden, direkt als String übergeben werden
                if (value !== '') {
                    return [{ [key]: (value as string).toString() }];
                }
                return [];
        }
    }

    /**
     * Setzt die Observables für die offenen Bearbeitungen und mapped den Wert aus dem EingabeFeld für offene Bearbeitungen
     * mit dem Namen des Eingabefelds zu einem KeyValuePair[].
     * Die möglichen offenen Bearbeitungen werden über das Backend eingeschränkt.
     * @param value OffeneBearbeitungen
     * @param key WahlDetailsuche.OFFENE_BEARBEITUNGEN
     * @returns KeyValuePair[]
     */
    private mapOffeneBearbeitungenToKeyValuePair(value: unknown, key: string): Dictionary<string>[] {
        value === OffeneBearbeitungen.Wahlkarten
            ? this.detailsucheService.wahlkartendruckOffen(true)
            : this.detailsucheService.wahlkartendruckOffen(false);
        value === OffeneBearbeitungen.Etiketten
            ? this.detailsucheService.etikettendruckOffen(true)
            : this.detailsucheService.etikettendruckOffen(false);
        value === OffeneBearbeitungen.Postaufgabeliste
            ? this.detailsucheService.postaufgabelisteOffen(true)
            : this.detailsucheService.postaufgabelisteOffen(false);

        return value === '' ? [] : [{ [key]: (value as OffeneBearbeitungen).toString() }];
    }

    /**
     * Redirekt zum Wahlbestand um die aktuelle Detailsuche zu löschen und den gesamten Wahlbestand,
     * ohne Einschränkung anzuzeigen
     */
    resetDetailSearch(): void {
        // löschen aller Formeinträge
        this.searchCriteriaForm.reset();
        // Entfernt Menüeinträge für Wahlkarten-, Etiketten- und Postaufgabelistendruck
        this.resetOffeneBearbeitungenObservables();
    }

    /**
     * Setzt die Detailsucheeingaben zurück und entfernt die Guid im URL
     */
    startNewSearch(): void {
        this.resetDetailSearch();
        this.schnellsucheService.emitClearSearchInput();
        this.router.navigate([], { relativeTo: this.route, queryParams: {} });
    }

    /**
     * Setzt die Observables für die offenen Bearbeitungen zurück,
     * welche die Menüeinträge für die Druckoperationen triggern.
     */
    resetOffeneBearbeitungenObservables(): void {
        // Sammeldruck für Wahlkarten und Etiketten aus dem Menü der Wahlbestandsliste über das Observable entfernen
        // Nachdruck-Observables zurücksetzen
        this.detailsucheService.resetOffeneBearbeitungenObservables();
    }

    /**
     * Schließt die seitliche Navigationsleiste
     */
    closeDetailSearch(): void {
        this.rightSidenavigationService.closeRightSidenav();
    }

    /**
     * Erstellt ein komplett befülltes WahlbezugsadresseDaten-Objekt
     * für die Anzeige im Eingabefeld zusammen.
     * @param wahlbezugsadresse
     * @returns WahlbezugsadresseDaten
     */
    completeWahlbezugsadresseDatenObject(wahlbezugsadresse: WahlbezugsadresseDaten): WahlbezugsadresseDaten {
        const strasse = wahlbezugsadresse.strasse;
        const hausnummer = wahlbezugsadresse.hausnummer ? ' ' + wahlbezugsadresse.hausnummer : '';
        const plz = wahlbezugsadresse.plz ? ', ' + wahlbezugsadresse.plz : '';
        let ort: string = '';
        if (plz) {
            ort = wahlbezugsadresse.ort ? ' ' + wahlbezugsadresse.ort : '';
        } else {
            ort = wahlbezugsadresse.ort ? ', ' + wahlbezugsadresse.ort : '';
        }

        wahlbezugsadresse.adresse = strasse + hausnummer + plz + ort;
        return wahlbezugsadresse;
    }

    /**
     * Füllt den Wert für den Wahlkarte-Status im Detailsuche-Form und updated über den DetailsucheService
     * die möglichen Bearbeitungen in der Wählerverzeichnis-Komponente.
     * @param value Wert als String
     * @param key Name des Eingabefelds im Detailsuche-Form
     */
    private setFormValueWahlkarteStatus(value: string, key: string): void {
        const wkStatus = +value;

        wkStatus === SucheWahlkartenStatusEnum.WahlkarteGedruckt
            ? this.detailsucheService.wahlkartenNachdruck(true)
            : this.detailsucheService.wahlkartenNachdruck(false);
        wkStatus === SucheWahlkartenStatusEnum.EtikettGedruckt
            ? this.detailsucheService.etikettenNachdruck(true)
            : this.detailsucheService.etikettenNachdruck(false);
        wkStatus === SucheWahlkartenStatusEnum.Versendet
            ? this.detailsucheService.postaufgabelisteNachdruck(true)
            : this.detailsucheService.postaufgabelisteNachdruck(false);

        this.searchCriteriaForm.get(key)?.setValue(wkStatus);
    }

    /**
     * Füllt den Wert für die offene Berbeitung im Detailsuche-Fom un updated über den DetailsucheService
     * die möglichen Bearbeitungen in der Wählerverzeichnis-Komponente.
     * @param value Wert als String
     * @param key Name des Eingabefelds im Detailsuche-Form
     */
    private setFormValueOffenBerbeitung(value: string, key: string): void {
        const bearbeitung = +value;

        bearbeitung === OffeneBearbeitungen.Wahlkarten
            ? this.detailsucheService.wahlkartendruckOffen(true)
            : this.detailsucheService.wahlkartendruckOffen(false);
        bearbeitung === OffeneBearbeitungen.Etiketten
            ? this.detailsucheService.etikettendruckOffen(true)
            : this.detailsucheService.etikettendruckOffen(false);
        bearbeitung === OffeneBearbeitungen.Postaufgabeliste
            ? this.detailsucheService.postaufgabelisteOffen(true)
            : this.detailsucheService.postaufgabelisteOffen(false);

        this.searchCriteriaForm.get(key)?.setValue(bearbeitung);
    }

    /**
     * Horcht auf die Input Felder der Detailsuche.
     * Wenn in ein Inputfeld geschrieben wird, soll der Suchparameter in der Schnellsuchleiste gelöscht werden.
     * Die Ergebnisliste soll aber erhalten bleiben.
     */
    @HostListener('oninput')
    clearQuickSearchPara(): void {
        this.schnellsucheService.emitClearSearchInput();
    }

    /**
     * Schließt die Detailsuche beim Drücken der Escape-Taste,
     * wenn dieses bearbeitet wird.
     */
    @HostListener('keydown.escape')
    closeDetailSearchWindow(): void {
        this.rightSidenavigationService.closeRightSidenav();
    }
}
