@if (!loading()) {
    <k5next-search-panel
        titel="Kontakte suchen"
        [showPanelCloseButton]="true"
        [showGespeicherteSuchen]="true"
        [gespeicherteSuchen]="gespeicherteSuchen$ | async"
        [additionalSuchkriterien]="additionalSuchkriterien$ | async"
        [selectedGespeicherteSuche]="selectedGespeicherteSucheSubject.value"
        (closePanel)="closeDetailsuchePanel()"
        (resetSuche)="resetSuche()"
        (shareGespeicherteSuche)="shareSuche($event)"
        (deleteGespeicherteSuche)="deleteGespeicherteSuche($event)"
        (tooltipLinkClick)="tooltipLinkClick($event)"
        (changeAdditionalSuchkriterium)="suchkriteriumSelectionChange($event)"
        (saveSuche)="openSaveSucheDialog()"
        (executeSuche)="executeSucheAndNavigate(false)"
        (executeGespeicherteSuche)="executeGespeicherteSuche($event)"
    >
        <div k5next-detailsuche-content>
            <form
                id="search-criteria-form-id"
                data-cy="search-criteria-form"
                class="p-4"
                novalidate
                tabindex="-1"
                [formGroup]="suchkriterienForm"
                (keydown.enter)="executeSucheAndNavigate(false)"
            >
                <!-- Art -->
                <mat-form-field class="w-full grow">
                    <mat-label id="art">Art</mat-label>
                    <mat-select
                        matInput
                        aria-labelledby="art"
                        formControlName="art"
                        data-cy="detail-search-kontakte-art"
                        k5StopEnterPropagation
                    >
                        @for (item of [1, 2, 0]; track $index) {
                            <mat-option [value]="item" data-cy="detail-search-kontakte-art-option">
                                {{ item | kontaktpersonArtToString }}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <!-- Registergeprüft -->
                @if (isKriteriumSelected('istRegistergebunden')) {
                    <mat-form-field class="w-full grow">
                        <mat-label id="istRegistergebunden">Registergeprüft</mat-label>
                        <mat-select
                            aria-labelledby="istRegistergebunden"
                            formControlName="istRegistergebunden"
                            k5StopEnterPropagation
                        >
                            @for (item of [0, 1, 2]; track $index) {
                                <mat-option [value]="item">{{ item | registergebunden }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }
                <!-- Notiz -->
                @if (isKriteriumSelected('notiz')) {
                    <mat-form-field class="w-full grow">
                        <mat-label id="notiz">Notiz</mat-label>
                        <input
                            formControlName="notiz"
                            matInput
                            tabindex="-1"
                            placeholder="Notiz"
                            data-cy="detail-search-kontakte-notiz-input"
                            autocomplete="off"
                        />
                        <button
                            type="button"
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="suchkriterienForm.controls.notiz.reset()"
                        >
                            <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                        </button>
                    </mat-form-field>
                }

                <!-- Kontakt bezogene Suchkriterien -->
                <div class="pt-4 text-xl">Kontakt</div>
                <!-- Name -->
                <mat-form-field class="w-full grow">
                    <mat-label id="name">Name</mat-label>
                    <input
                        aria-labelledby="name"
                        matInput
                        formControlName="name"
                        autocomplete="off"
                        data-cy="detail-search-kontakte-input-name-text"
                    />
                    @if (suchkriterienForm.controls.name.value) {
                        <button
                            type="button"
                            matSuffix
                            tabindex="-1"
                            mat-icon-button
                            aria-label="Clear"
                            (click)="suchkriterienForm.controls.name.reset()"
                        >
                            <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                        </button>
                    }
                </mat-form-field>
                <!-- Geburtsdatum -->
                <mat-form-field class="w-full grow">
                    <mat-label id="geburtsdatum">Geburtsdatum</mat-label>
                    <input
                        aria-labelledby="geburtsdatum"
                        matInput
                        formControlName="geburtsdatum"
                        data-cy="detail-search-kontakte-geburtsdatum-input"
                        autocomplete="off"
                    />
                    @if (suchkriterienForm.controls.geburtsdatum.value) {
                        <button
                            type="button"
                            matSuffix
                            tabindex="-1"
                            mat-icon-button
                            aria-label="Clear"
                            (click)="suchkriterienForm.controls.geburtsdatum.reset()"
                        >
                            <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                        </button>
                    }
                </mat-form-field>
                <!-- Erreichbarkeit -->
                <mat-form-field class="w-full grow">
                    <mat-label id="erreichbarkeit">Erreichbarkeit</mat-label>
                    <input
                        aria-labelledby="erreichbarkeit"
                        matInput
                        formControlName="erreichbarkeit"
                        data-cy="detail-search-kontakte-erreichbarkeit-input"
                        autocomplete="off"
                        (keydown.enter)="executeSucheAndNavigate(false)"
                    />
                    @if (suchkriterienForm.controls.erreichbarkeit.value) {
                        <button
                            type="button"
                            matSuffix
                            tabindex="-1"
                            mat-icon-button
                            aria-label="Clear"
                            (click)="suchkriterienForm.controls.erreichbarkeit.reset()"
                        >
                            <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                        </button>
                    }
                </mat-form-field>
                <!-- Gruppe -->
                @if (isKriteriumSelected('gruppe')) {
                    <mat-form-field class="w-full grow">
                        <mat-label id="gruppe">Gruppe</mat-label>
                        <mat-select
                            aria-labelledby="gruppe"
                            formControlName="gruppe"
                            data-cy="detail-suche-gruppe"
                            k5StopEnterPropagation
                        >
                            <mat-option [value]="null"> - </mat-option>
                            @for (gruppe of gruppen$ | async; track $index) {
                                <mat-option [value]="gruppe">{{ gruppe.name }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }
                <!-- Firmenbuchnummer -->
                @if (isKriteriumSelected('firmenbuchNr')) {
                    <mat-form-field class="w-full grow">
                        <mat-label id="firmenbuchNr">Firmenbuchnummer</mat-label>
                        <input
                            aria-labelledby="firmenbuchNr"
                            matInput
                            formControlName="firmenbuchNr"
                            data-cy="detail-search-kontakte-firmenbuch-nr-input"
                            autocomplete="off"
                        />
                        @if (suchkriterienForm.controls.firmenbuchNr.value) {
                            <button
                                type="button"
                                matSuffix
                                mat-icon-button
                                aria-label="Clear"
                                (click)="suchkriterienForm.controls.firmenbuchNr.reset()"
                            >
                                <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                            </button>
                        }
                    </mat-form-field>
                }
                <!-- UID-Nummer -->
                @if (isKriteriumSelected('uidNummer')) {
                    <mat-form-field class="w-full grow">
                        <mat-label id="uidNummer">UID-Nummer</mat-label>
                        <input
                            aria-labelledby="uidNummer"
                            matInput
                            formControlName="uidNummer"
                            data-cy="detail-search-kontakte-uid-nr-input"
                            autocomplete="off"
                        />
                        @if (suchkriterienForm.controls.uidNummer.value) {
                            <button
                                type="button"
                                matSuffix
                                tabindex="-1"
                                mat-icon-button
                                aria-label="Clear"
                                (click)="suchkriterienForm.controls.uidNummer.reset()"
                            >
                                <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                            </button>
                        }
                    </mat-form-field>
                }
                <!-- Ansprechpartner Funktion -->
                @if (isKriteriumSelected('ansprFunktion')) {
                    <mat-form-field class="w-full grow">
                        <mat-label id="ansprFunktion">Ansprechpartner Funktion</mat-label>
                        <input
                            aria-labelledby="ansprFunktion"
                            matInput
                            data-cy="detail-search-kontakte-ansprechpartner-input"
                            formControlName="ansprFunktion"
                            placeholder="Ansprechpartner Funktion"
                            autocomplete="off"
                        />
                        @if (suchkriterienForm.controls.ansprFunktion.value) {
                            <button
                                type="button"
                                matSuffix
                                tabindex="-1"
                                mat-icon-button
                                aria-label="Clear"
                                (click)="suchkriterienForm.controls.ansprFunktion.reset()"
                            >
                                <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                            </button>
                        }
                    </mat-form-field>
                }
                <!-- Tag -->
                @if (isKriteriumSelected('tag')) {
                    <mat-form-field class="w-full grow">
                        <mat-label id="tag">Tag</mat-label>
                        <mat-chip-grid #chipGrid aria-label="Tag Auswahl">
                            @for (tag of selectedTags; track tag) {
                                <mat-chip-row (removed)="removeTagFromChipList(tag)">
                                    {{ tag }}
                                    <button title="entfernen" matChipRemove [attr.aria-label]="tag + ' entfernen'">
                                        <i
                                            class="fa-duotone fa-minus-circle fa-fw cursor-pointer"
                                            aria-hidden="true"
                                        ></i>
                                    </button>
                                </mat-chip-row>
                            }
                        </mat-chip-grid>
                        <input
                            type="text"
                            placeholder="Tag ..."
                            #tagInput
                            [formControl]="suchkriterienForm.controls.tag"
                            [matChipInputFor]="chipGrid"
                            [matAutocomplete]="auto"
                            data-cy="detail-search-kontakte-tag-input"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="addTagToChipList($event)"
                            k5StopEnterPropagation
                        />
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
                            @for (tag of selectableTags$ | async; track tag) {
                                <mat-option data-cy="detail-search-kontakte-tag-input-option" [value]="tag">{{
                                    tag
                                }}</mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
                }

                <!-- Suchkriterien Adresse: -->
                <div class="pt-4 text-xl">Adresse</div>
                <!-- Straße -->
                <mat-form-field class="w-full grow">
                    <mat-label id="strasse">Straße</mat-label>
                    <input
                        aria-labelledby="strasse"
                        matInput
                        formControlName="strasse"
                        autocomplete="off"
                        data-cy="detail-search-kontakte-strasse-input"
                    />
                    @if (suchkriterienForm.controls.strasse.value) {
                        <button
                            type="button"
                            matSuffix
                            tabindex="-1"
                            mat-icon-button
                            aria-label="Clear"
                            (click)="suchkriterienForm.controls.strasse.reset()"
                        >
                            <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                        </button>
                    }
                </mat-form-field>
                <!-- Hausnummer -->
                <mat-form-field class="w-full">
                    <mat-label id="orientierungsnummer">Hausnummer</mat-label>
                    <input
                        aria-labelledby="orientierungsnummer"
                        matInput
                        formControlName="orientierungsnummer"
                        data-cy="detail-search-kontakte-orientierungsnummer-input"
                        autocomplete="off"
                    />
                    @if (suchkriterienForm.controls.orientierungsnummer.value) {
                        <button
                            type="button"
                            matSuffix
                            tabindex="-1"
                            mat-icon-button
                            aria-label="Clear"
                            (click)="suchkriterienForm.controls.orientierungsnummer.reset()"
                        >
                            <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                        </button>
                    }
                </mat-form-field>
                <!-- Ort -->
                <mat-form-field class="w-full">
                    <mat-label id="ort">Ort</mat-label>
                    <input
                        aria-labelledby="ort"
                        matInput
                        formControlName="ort"
                        autocomplete="off"
                        data-cy="detail-search-kontakte-input-ort"
                    />
                    @if (suchkriterienForm.controls.ort.value) {
                        <button
                            type="button"
                            matSuffix
                            tabindex="-1"
                            mat-icon-button
                            aria-label="Clear"
                            (click)="suchkriterienForm.controls.ort.reset()"
                        >
                            <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                        </button>
                    }
                </mat-form-field>
                <!-- Postleitzahl -->
                @if (isKriteriumSelected('postleitzahl')) {
                    <mat-form-field class="w-full">
                        <mat-label id="postleitzahl">Postleitzahl</mat-label>
                        <input
                            aria-labelledby="postleitzahl"
                            matInput
                            formControlName="postleitzahl"
                            data-cy="detail-search-kontakte-plz-input"
                            autocomplete="off"
                        />
                        @if (suchkriterienForm.value.postleitzahl) {
                            <button
                                type="button"
                                matSuffix
                                tabindex="-1"
                                mat-icon-button
                                aria-label="Clear"
                                (click)="suchkriterienForm.controls.postleitzahl.reset()"
                            >
                                <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                            </button>
                        }
                    </mat-form-field>
                }
                <!-- Gemeindekennziffer -->
                @if (isKriteriumSelected('gemeindekennziffer')) {
                    <mat-form-field class="w-full">
                        <mat-label id="gemeindekennziffer">Gemeindekennziffer</mat-label>
                        <input
                            aria-labelledby="gemeindekennziffer"
                            matInput
                            formControlName="gemeindekennziffer"
                            data-cy="detail-search-kontakte-gkz-input"
                            autocomplete="off"
                        />
                        @if (suchkriterienForm.controls.gemeindekennziffer.value) {
                            <button
                                type="button"
                                matSuffix
                                tabindex="-1"
                                mat-icon-button
                                aria-label="Clear"
                                (click)="suchkriterienForm.controls.gemeindekennziffer.reset()"
                            >
                                <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                            </button>
                        }
                    </mat-form-field>
                }
                <!-- Staat -->
                @if (isKriteriumSelected('staatISO3')) {
                    <mat-form-field class="w-full">
                        <mat-label id="staatISO3"> Staat</mat-label>
                        <input
                            aria-labelledby="staatISO3"
                            matInput
                            formControlName="staatISO3"
                            data-cy="detail-search-kontakte-staat-input"
                            autocomplete="off"
                        />
                        @if (suchkriterienForm.controls.staatISO3.value) {
                            <button
                                type="button"
                                matSuffix
                                tabindex="-1"
                                mat-icon-button
                                aria-label="Clear"
                                (click)="suchkriterienForm.controls.staatISO3.reset()"
                            >
                                <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                            </button>
                        }
                    </mat-form-field>
                }
            </form>
        </div>
    </k5next-search-panel>
} @else {
    <k5next-loading message="Detailsuche wird geladen"></k5next-loading>
}
