// @ts-strict-ignore
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { extract } from '@extractus/feed-extractor';
import { RssFeed } from '@shared/models/rssFeed';
import { RssItem } from '@shared/models/rssItem';

@Component({
    selector: 'k5-rss-feed-widget',
    templateUrl: './rss-feed-widget.component.html',
    styleUrls: ['./rss-feed-widget.component.scss']
})
export class RssFeedWidgetComponent implements OnInit, OnDestroy, OnChanges {
    rssFeed: RssFeed;
    slides: RssItem[];

    currentIndex = 0;

    interval: number;

    loading: boolean = true;

    @Input()
    feedUrl: string;

    constructor() {}

    /**
     * Gibt ein Entry vom RSS Feed zurück
     */
    get entry(): RssItem {
        if (this.slides?.length > 0) {
            return this.slides[this.currentIndex];
        }
        return {
            description: '',
            image: '',
            title: '',
            id: ''
        };
    }

    ngOnInit(): void {
        this.interval = setInterval((val) => {
            if (this.slides) {
                this.next();
            }
        }, 5000);
        if (this.feedUrl) {
            this.loadWidgetEinstellungen(this.feedUrl);
        }
    }

    /**
     * Lädt das RSS Widget neu, wenn sich die URL geändert hat
     * @param changes SimpleChanges
     */
    ngOnChanges(changes: SimpleChanges): void {
        if(changes['feedUrl']?.currentValue){
            this.loadWidgetEinstellungen(changes['feedUrl'].currentValue);
        }
        else{
            this.slides = null;
        }
    }

    /**
     * Cleart den Intervall
     */
    ngOnDestroy(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    /**
     * Lädt die Widget Einstellungen und startet den Timer
     */
    loadWidgetEinstellungen(feedUrl: string): void {
        this.loading = true;
        if (feedUrl) {
            this.getFeedTest(feedUrl);
        }
    }

    /**
     * Wechselt zum nächsten Bild
     * @returns Index
     */
    next(): void {
        if (this.currentIndex === this.slides.length - 1) {
            this.currentIndex = 0;
            return;
        }
        this.currentIndex++;
    }

    /**
     * Wechselt zum vorherigen Bild
     * @returns Index
     */
    previous(): void {
        if (this.currentIndex === 0) {
            this.currentIndex = this.slides.length - 1;
            return;
        }
        this.currentIndex--;
    }

    /**
     * Öffnet einen Link in einem neuen Tab
     * @param url Url
     */
    openLinkInTab(url: string): void {
        window.open(url, '_blank');
    }

    /**
     * Extrahiert den RSS Feed in eine Variable
     * @param url URL des RSS Feeds
     */
    async getFeedTest(url: string) {
        try {
            this.rssFeed = await extract(
                url,
                {
                    getExtraEntryFields: (feedEntry: any) => {
                        const { enclosure, image, description } = feedEntry;

                        return {
                            image: this.getImage(enclosure, image, description)
                        };
                    }
                },
                {
                    headers: {}
                }
            );
        } catch (error) {
            this.loading = false;
            console.error(error);
        }

        this.slides = this.rssFeed?.entries.map((entry) => {
            return {
                title: entry.title,
                description: this.decodeHtml(entry?.description),
                image: entry.image,
                link: entry.link,
                id: entry.id
            };
        });
        this.loading = false;
    }

    /**
     * Dekodiert alle html Tags und Kodierungen
     * Wandelt &quot; in Anführungszeichen um
     * @param html HTML Text als String
     * @returns Dekodierter String
     */
    decodeHtml(html: string): string {
        if (!html) {
            return '';
        }
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }

    /**
     * Gibt die URL des Bildes zurück, je nach dem welches Feld befüllt ist
     * @param enclosure Enclosure
     * @param image Image
     * @param description Description
     * @returns URL
     */
    getImage(enclosure: any, image: any, description: string): string | null {
        if (enclosure) {
            return enclosure['@_url'];
        } else if (image) {
            return image;
        } else {
            return this.sliceDescription(description);
        }
    }

    /**
     * Gibt die URL von einem HTML Tag in einer Beschreibung zurück
     * @param description Beschreibung eines Feed Entries
     * @returns
     */
    sliceDescription(description: string): string | null {
        if (description) {
            const index = description.indexOf('src="') + 4;
            const index2 = description.indexOf('"', index + 1);
            return description.slice(index + 1, index2);
        }
        return null;
    }
}
