// @ts-strict-ignore
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterLinkConstants } from '@core/constants/url-constants';
import { AlertService } from '@core/services/alert.service';
import { KontaktmanagementDetailsuche } from '@shared/constants/shared-constants';
import { KontaktmanagementClient } from '@shared/models/kontaktmanagementClient';
import { DateUtilsService } from '@shared/services/date-utils.service';
import { GruppenService } from '@shared/services/gruppen.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'k5-gespeicherte-gruppen-widget',
    templateUrl: './gespeicherte-gruppen-widget.component.html',
    styleUrls: ['./gespeicherte-gruppen-widget.component.scss']
})
export class GespeicherteGruppenWidgetComponent implements OnInit {
    DATE_UTILS_SERVICE = DateUtilsService;
    titelText = 'Meine gespeicherten Gruppen';

    gespeicherteGruppenSubject: BehaviorSubject<KontaktmanagementClient.GruppeResponse[]> = new BehaviorSubject(null);
    gespeicherteGruppen$: Observable<KontaktmanagementClient.GruppeResponse[]> =
        this.gespeicherteGruppenSubject.asObservable();

    filteredGespeicherteGruppen: KontaktmanagementClient.GruppeResponse[] = [];

    loading = true;

    constructor(
        private gruppenService: GruppenService,
        private alertService: AlertService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.gruppenService.getGruppen().subscribe({
            next: (response: KontaktmanagementClient.GruppeResponse[]) => {
                if (response) {
                    this.gespeicherteGruppenSubject.next(response);
                    this.filteredGespeicherteGruppen = response;
                }
                this.loading = false;
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler bei der Abfrage der gespeicherten Gruppen');
                this.loading = false;
            }
        });
    }

    /**
     * Filtert die Datenmenge nach dem Filtertext.
     * @param text
     */
    search(text: string): void {
        if (text) {
            text = text.toLowerCase();
            this.filteredGespeicherteGruppen = this.gespeicherteGruppenSubject.value.filter(
                (gespeicherteGruppe: KontaktmanagementClient.GruppeResponse) => {
                    return (
                        gespeicherteGruppe.daten.name.toLowerCase().includes(text) ||
                        this.DATE_UTILS_SERVICE.convertDateToFormattedDateString(
                            gespeicherteGruppe.daten.letzteAenderung
                        )
                            .toLowerCase()
                            .includes(text)
                    );
                }
            );
        } else {
            this.filteredGespeicherteGruppen = this.gespeicherteGruppenSubject.value;
        }
    }

    /**
     * Navigiert zum KM mit der Gruppe Id
     * @param searchId Id der Gruppe
     */
    navigateToKM(searchId: string): void {
        this.router.navigateByUrl(
            `${RouterLinkConstants.KONTAKTE_LISTE}?${KontaktmanagementDetailsuche.GRUPPE_KEY}=${searchId}`
        );
    }
}
