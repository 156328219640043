import { Pipe, PipeTransform } from '@angular/core';
import { KontaktpersonArt } from '@k5next/ui-components';
import { Personenart } from '@shared/models/personenart';

@Pipe({
    name: 'kontaktpersonArtToPersonenart'
})
export class KontaktpersonArtToPersonenartPipe implements PipeTransform {
    /**
     * Transformiert KontakpersonArt in Personenart.
     * @param KontaktpersonArt source
     * @returns transformierte Personenart
     */
    transform(source: KontaktpersonArt): Personenart | null {
        switch (source) {
            case KontaktpersonArt.Natuerlich:
                return Personenart.Natuerlich;
            case KontaktpersonArt.Unternehmen:
                return Personenart.Unternehmen;
            default:
                return null;
        }
    }
}

@Pipe({
    name: 'personenartToKontaktpersonArt'
})
export class PersonenArtToKontaktpersonArtPipe implements PipeTransform {
    /**
     * Transformiert KontakpersonArt in Personenart.
     * @param KontaktpersonArt source
     * @returns transformierte Personenart
     */
    transform(source: Personenart): KontaktpersonArt | null {
        switch (source) {
            case Personenart.Natuerlich:
                return KontaktpersonArt.Natuerlich;
            case Personenart.Unternehmen:
                return KontaktpersonArt.Unternehmen;
            default:
                return KontaktpersonArt.Alle;
        }
    }
}
